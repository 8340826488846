<template>
  <div class="w-100 p-l-30 p-r-30">
    <div v-if="!isDetailView">
      <div class="row d-flex justify-content-between m-r-0">
        <div class="col select-tournament">
          <select v-model="tournamentId"
                  class="form-control color-33 select-style select-tournament form-select"
                  @change="getDataTournament">
            <option
                v-for="(tournament, tournamentId) in tournamentList"
                :key="'tournamentId'+ tournamentId"
                :value="tournament.tournamentId"
            >
              {{ tournament.tournamentName }}
            </option>
          </select>
          <div v-show="emptyTournament" class="form-error">表示期間内に大会はありません</div>
        </div>
        <div class="float-right width-150 scan-btn">
          <button class="btn btn-primary height-48 width-144 tournament-search-button"
                  v-bind:disabled="tournamentId === 0"
                  @click="scanResultCTA">
            入場管理
          </button>
        </div>
      </div>
      <div class="row m-t-25 m-b-20">
        <div class="col filtering-column135">
          <label for="filter-select">ステータス</label>
          <select v-model="healthStatus" class="form-control mt-1 color-33 filter-select form-select select-tournament"
                  @change="getData">
            <option v-for="(healthStatusName, healthStatusId) in healthStatuses" :key="healthStatusId"
                    :value="healthStatusId">
              {{ healthStatusName }}
            </option>
          </select>
        </div>
        <div class="col filtering-column135">
          <label for="filter-select">ロール</label>
          <select v-model="roleId"
                  class="form-control color-33 mt-1 filter-select form-select select-tournament"
                  @change="getData">
            <option :value="Number('0')">すべて</option>
            <option v-for="(role, roleId) in roles" :value="role.roleId">
              {{ role.roleName }}
            </option>
          </select>
        </div>
        <!--
        <div class="col filtering-column135">
          <label for="filter-select">ワクチン接種</label>
          <select v-model="coronaVaccinationCount" class="form-control color-33 mt-1 filter-select form-select select-tournament"
                  @change="getData">
            <option v-for="(hasCoronaVaccination,hasCoronaVaccinationId) in hasCoronaVaccinations" :key="hasCoronaVaccinationId"
                    :value="hasCoronaVaccinationId">
              {{hasCoronaVaccination}}
            </option>
          </select>
        </div>
        <div class="col filtering-column135">
          <label for="filter-select">PCR検査</label>
          <select v-model="pcrTestResultStatus" class="form-control color-33 mt-1 filter-select form-select select-tournament"
                  @change="getData">
            <option v-for="(pcrTestResultStatus,pcrTestResultStatusId) in pcrTestResultStatuses" :key="pcrTestResultStatusId"
                    :value="pcrTestResultStatusId">
            {{pcrTestResultStatus}}
            </option>
          </select>
        </div>
        -->
        <div class="col filtering-column135">
          <label for="filter-select">アカウント状態</label>
          <select v-model="accountStatus" class="form-control color-33 mt-1 filter-select form-select select-tournament"
                  @change="getData">
            <option v-for="(accountStatusName, accountStatusId) in accountStatuses" :key="accountStatusId"
                    :value="accountStatusId">
              {{ accountStatusName }}
            </option>
          </select>
        </div>
        <div class="col filtering-column270">
          <label for="keywords">キーワード</label>
          <div class="input-group mt-1">
            <input id="keywords" v-model="keyword"
                   class="form-control py-2 bg-transparent color-33 border-right-0 filter-search-form"
                   placeholder="キーワードを入力"
                   type="search"
                   @keydown.enter="onKeyDown"/>
            <span class="input-group-append">
              <button class="btn-submit input-group-text bg-transparent p-2" type="submit" @click="search">
                <i class="fa fa-search"></i>
              </button>
            </span>
            <div v-show="errors['keyword']" class="form-error">{{ errors['keyword'] }}</div>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="width-170">
          <button
                  class="btn custom-button-outline fw-bold d-flex font-16 justify-content-around p-l-0 px-1"
                  v-bind:disabled="tournamentId === 0"
                  v-if="isJTA"
                  @click="getTournamentCsv">
            <img class="csv-image" src="../assets/images/ic_download_csv.svg"/>CSVダウンロード
          </button>
        </div>
      </div>
      <div class="datatable">
        <Datatable :columns="columns" :getData="getData" :loading="loading" :response="response" :sortable="true"
                   @cta="cta">
        </Datatable>
      </div>
    </div>
  </div>
</template>
<script>
import {datatable, defaults, gender} from "@/constants"
import Datatable from "../components/datatable/Datatable"
import Table from "../components/datatable/Table"
import Common from "jsadminclient/common"
import {mapGetters} from "vuex"
import Helper from "jsadminclient/helper"

export default {
  name: "Record",
  components: {Datatable, Table},
  props: {
    tournament: {type: Object, default: null},
    defaultResponse: {type: Object, default: null}
  },
  data() {
    return {
      response: {
        sort: "healthStatus",
        order: "asc",
        current_page: 1,
        per_page: datatable.default.perPage,
      },
      recordResponse: {
        sort: "",
        accountStatus: "",
        prevUserId: "",
        nextUserId: "",
        prevUserName: "",
        nextUserName: "",
        tournamentName: "",
      },
      isDetailView: false,
      reloadKey: 1,
      keyword: "",
      gender: gender,
      loading: true,
      emptyTournament: false,
      errors: [],
      columns: datatable.columns.records,
      recordColumns: datatable.columns.record,
      healthStatus: defaults.healthStatus,
      healthStatuses: defaults.healthStatuses,
      accountStatus: defaults.accountStatus,
      accountStatuses: defaults.accountStatuses,
      alert: defaults.alert,
      alerts: defaults.alerts,
      roleId: defaults.roleId,
      roles: {},
      hasCoronaVaccinations: defaults.hasCoronaVaccinations,
      coronaVaccinationCount: defaults.coronaVaccinationCount,
      pcrTestResultStatus: defaults.pcrTestResultStatus,
      pcrTestResultStatuses: defaults.pcrTestResultStatuses,
      tournamentId: defaults.tournamentId,
      tournamentList: {},
      userId: 0,
      date: '',
      tournamentDetails: {},
      isJTA: false
    };
  },
  computed: {
    ...mapGetters(['config']),
  },
  mounted() {
    this.restoreSearchQuery()
    this.initialPageLoadHandler();
    this.loadTournamentList();
    this.getRoles();
    this.getUser();
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode !== 13) {
        return
      }
      this.search()
    },
    initialPageLoadHandler() {
      if (this.defaultResponse) {
        this.response = this.defaultResponse
      }

      Object.assign(this.columns[0], {
        format: (healthStatus) => {
          return Helper.showHealthStatus(healthStatus)
        },
      });

      Object.assign(this.columns[1], {
        format: (lastName) => {
          return lastName
        },
      });

      Object.assign(this.columns[2], {
          format: (firstName) => {
              return firstName
          },
      });

      Object.assign(this.columns[3], {
        format: (role) => {
          return role
        },
      });

      // Object.assign(this.columns[4], {
      //   format: (latestPcrTestResult) => {
      //     return Common.getLatestPcrTestResult(latestPcrTestResult)
      //   },
      // });

      Object.assign(this.columns[4], {
        format: (organization) => {
          return organization
        },
      });

      Object.assign(this.columns[5], {
        format: (user_tournament_id) => {
          return Helper.showCTA()
        },
      });

      $(".tooltip").hide();
      this.getData();
    },
    search() {
      this.response.current_page = 1
      this.getData()
    },
    createSearchQuery(orgQuery = null) {
      return Common.appendSearchQuery(
          orgQuery,
          {
            healthStatus: this.healthStatus,
            role: this.roleId,
            coronaVaccinationCount: this.coronaVaccinationCount,
            pcrTestResultStatus: this.pcrTestResultStatus,
            accountStatus: this.accountStatus,
            keyword: this.keyword,
            sort: this.response.sort,
            order: this.response.order,
            currentPage: this.response.current_page,
            perPage: this.response.per_page,
            tournament: this.tournamentId
          })
    },
    restoreSearchQuery() {
      Common.restoreQuery(this, 'tournamentId', Common.getNumberOrNull(this.$route.query.tournament))
      Common.restoreQuery(this, 'healthStatus', this.$route.query.healthStatus)
      Common.restoreQuery(this, 'roleId', this.$route.query.role)
      Common.restoreQuery(this, 'coronaVaccinationCount',this.$route.query.coronaVaccinationCount)
      Common.restoreQuery(this, 'pcrTestResultStatus',this.$route.query.pcrTestResultStatus)
      Common.restoreQuery(this, 'alert', this.$route.query.alert)
      Common.restoreQuery(this, 'accountStatus', this.$route.query.accountStatus)
      Common.restoreQuery(this, 'keyword', this.$route.query.keyword)
      Common.restoreQuery(this.response, 'sort', this.$route.query.sort)
      Common.restoreQuery(this.response, 'order', this.$route.query.order)
      Common.restoreQuery(this.response, 'per_page', this.$route.query.perPage)
      Common.restoreQuery(this.response, 'current_page', this.$route.query.currentPage)
    },
    async getDataTournament() {
      Common.saveSelectTournamentIdCommon(this.tournamentId)
      await this.getData()
    },
    async getData() {
      if (this.emptyTournament) {
        this.loading = false
        this.response.data = []
        return
      }
      if (this.tournamentId === null || this.tournamentId === 0) {
        return
      }
      try {
        this.errors = []
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token))
        var result = await api.tournamentsTournamentIdRecordsGetAsync(
            this.tournamentId,
            this.healthStatus,
            this.roleId,
            this.coronaVaccinationCount,
            this.pcrTestResultStatus,
            this.alert,
            this.accountStatus,
            this.keyword,
            this.response.sort,
            this.response.order,
            this.response.current_page,
            this.response.per_page
        )
        if (result.items) {
          this.loading = false
          this.reloadKey += 1
          this.response = Helper.formatResponse(result)
          Common.updateCurrentPageQuery(this, this.createSearchQuery())
        }
      } catch (e) {
        this.response.data = []
        this.loading = false
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
      async getTournamentCsv() {
          if (this.emptyTournament) {
              this.loading = false
              this.response.data = []
              return
          }
          if (this.tournamentId === null || this.tournamentId === 0) {
              return
          }
          try {
              this.errors = []
              var api = new AdminApi.JTADownloadApi(Helper.apiConfig(this.config.token))
              var result = await api.jtaDownloadRecordsTournamentIdGetAsync(
                  this.tournamentId,
                  this.healthStatus,
                  this.roleId,
                  this.coronaVaccinationCount,
                  this.pcrTestResultStatus,
                  this.alert,
                  this.accountStatus,
                  this.keyword,
                  this.response.sort,
                  this.response.order,
                  this.response.current_page,
                  this.response.per_page
              )
              if (result) {
                  const a = window.document.createElement('a')
                  const universalBOM = "\uFEFF"
                  const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), universalBOM + result], {type: 'data:application/csv; charset=UTF-8'})
                  a.href = window.URL.createObjectURL(blob)
                  const today = moment(new Date()).format('YYYYMMDD_HHmmss')
                  const tournamentName = this.tournamentList.find((f) => f.tournamentId === this.tournamentId).tournamentName
                  a.download = today + '_record_' + tournamentName + '.csv'
                  document.body.appendChild(a)
                  a.click()
                  document.body.removeChild(a)
                  this.loading = false
                  this.reloadKey += 1
                  Common.updateCurrentPageQuery(this, this.createSearchQuery())
              }
          } catch (e) {
              this.response.data = []
              this.loading = false
              this.errors = Common.getErrorList(e.response, this.errors)
              const isTokenError = Common.isTokenError(e.response)
              if (isTokenError) {
                  this.$router.push({name: 'Login'});
              } else {
                  Common.handleAPIError(e)
              }
          }
      },
    backToRecord() {
      this.isDetailView = false
    },
    scanResultCTA() {
      this.$router.push({
        name: 'Scan',
        params: {
          tournamentId: Number(this.tournamentId),
        },
        query: this.createSearchQuery()
      })
    },
    cta(key, row) {
      //this.isDetailView = true
      this.userId = row[key];
      this.$router.push({
        name: "UserRecord",
        params: {
          tournamentId: Number(this.tournamentId),
          userId: this.userId,
        },
        query: this.createSearchQuery()
      })
    },
    async getTournamentRecord() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsTournamentIdUserRecordsUserIdGetAsync(
            this.tournamentId,
            this.userId,
            this.healthStatus,
            this.role,
            this.coronaVaccinationCount,
            this.pcrTestResultStatus,
            this.alert,
            this.accountStatus,
            this.keyword,
            this.response.sort,
            this.response.order
        );
        this.loading = false;
        this.recordResponse = result;
        this.recordResponse.data = result.items.map((item) => {
          return {
            healthStatus: this.healthStatuses[item.healthStatus],
            user_id: result.user.userId,
            date: item.date,
            bodyTemp: item.bodyTemp,
            remarks: item.remarks,
            alerts: item.alerts,
          };
        });
      } catch (e) {
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getTournamentRecordDetails() {
      try {
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        this.tournamentDetails = await api.tournamentsTournamentIdUserRecordsUserIdDetailGetAsync(
            this.tournamentId,
            this.userId,
            this.date
        );
        if (this.tournamentDetails != null) {
          this.loading = false
          console.log(this.tournamentDetails)
        }
      } catch (e) {
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async loadTournamentList() {
      try {
        const start = Common.getHomeStartDate()
        const end = Common.getHomeEndDate()
        var api = new AdminApi.TournamentApi(Helper.apiConfig(this.config.token));
        var result = await api.tournamentsListGetAsync(start, end);
        if (result.items) {
          this.loading = false;
          this.tournamentList = result.items;
          if (this.$route.params.tournament) {
            this.tournamentId = this.$route.params.tournament;
          } else {
            const savedId = this.tournamentId > 0 ? this.tournamentId : Common.getSelectTournamentIdCommon()
            if (savedId !== 0 && this.tournamentList.find((f) => f.tournamentId === savedId)) {
              this.tournamentId = savedId
            } else {
              if (this.tournamentList.length > 0) {
                this.tournamentId = this.tournamentList[0].tournamentId;
              } else {
                this.emptyTournament = true
              }
            }
          }

          this.getData()
        }
      } catch (e) {
        this.loading = false
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getRoles() {
      try {
        const api = new AdminApi.SystemApi(Helper.apiConfig(this.config.token));
        var result = await api.systemRolesGetAsync();
        this.roles = result.items;
      } catch (e) {
        this.roles = [];
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      try {
        const api = Common.createUserApi(token)
        const jsonObject = await api.usersUserIdGetAsync(user_id)
        this.isJTA = jsonObject.isJTA
      } catch (error) {
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
  },
};
</script>

<style lang="scss">
.record table {
  display: table;

  tr {
    td {
    }

    th,
    td {
      &:nth-child(1), &:nth-child(2) {
        width: 130px;
      }

      &:nth-child(6) {
        width: 290px;
      }

      //&:nth-child(7) {
      //  width: 50px;
      //}

      @media only screen and (max-width: 1200px) {
        &:nth-child(1) {
          width: 123px;
        }
        &:nth-child(2) {
          width: 108px;
        }
        &:nth-child(3), &:nth-child(4) {
          width: 133px;
        }

      }
      @media only screen and (max-width: 1100px) {
        &:nth-child(5) {
          width: 58px;
        }
        &:nth-child(6) {
          width: 102px;
        }
      }
      @media only screen and (max-width: 1030px) {
        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          width: auto;
        }
      }
      @media only screen and (max-width: 994px) {
        //&:nth-child(3), &:nth-child(4) {
        //  width: 133px;
        //}
        //&:nth-child(5) {
        //  width: 58px;
        //}
        //&:nth-child(6) {
        //  width: 102px;
        //}
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.tournament-search-button {
  height: 48px;
  background: #c95d6c;
  border-radius: 4px;
  color: #ffffff;
  width: 100%;
}

.custom-button-outline.btn {
  background: #ffffff;
  color: #c95d6c;
  border: 1px solid #c95d6c;
  border-radius: 4px;
  width: 160px;
  height: 32px;
  padding-top: 3px;
}

.csv-image{
  padding-top: 3px;
}

.filter-select {
  font-size: 13px;
  height: 32px !important;
  line-height: 13px;
}

.filter-search-form {
  height: 32px !important;
}

.btn-submit {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.bg-transparent:not(:hover) {
  background: transparent;
}

.fa {
  line-height: 0.98;
}

// Detail view

.filter-select {
  background: #f2ecf0;
}

.select-tournament.form-control {
  background: #f2ecf0;
  color: #888888;
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 13px 13px;
}

.left {
  margin: auto;
  width: 100%;
  text-align: left;
  height: 36px;
  line-height: 20px;
  border-bottom: 1px solid #e2e2e2;
}

.date-div.spbetween {
  height: 76px;
  border-bottom: 1px solid #e2e2e2;
  line-height: 76px;
}

.content_wrapper {
  padding: 0 32px;
}

.content {
  top: 15px !important;
}

.nvlink-container {
  border-bottom: 1px solid #e2e2e2;
}

.spbetween {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.form-holder {
  padding: 0 80px;
}

::placeholder {
  color: #333333 !important;
}

.pd {
  padding: 5px 0;
}

textarea {
  background: #faf7f9;
  min-height: 207px;
}

.custom_btn {
  width: 350px;
}

.bg_black {
  background: #333333 0% 0% no-repeat padding-box;
}

.custom-contianer {
  label {
    padding: 17px 0 10px 0;

    &.pd {
      padding: 20px 0;
    }
  }
}

.record {
  .bg-success {
    background-color: #7EC100 !important;
  }

  .bg-danger {
    background-color: #C1001B !important;
  }

  .bg-nomal {
    background-color: #FFFFFF !important;
    color: #333 !important;
  }

  .text-success {
    color: #7EC100 !important;
  }

  .text-danger {
    color: #C1001B !important;
    padding-left: 18px;
  }

  .enterStatus {
    width: 160px;
  }

  .data-info {
    max-width: calc(100% - 160px);
  }

  .card {
    &-header {
      color: white;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: 500;
    }

    &-detail {
      background-color: #FAF7F9;
      border: 1px solid #DACED1;
      border-radius: 4px 4px 4px 4px;

      .admission-status {
        background: #ffffff;
        border: 1px solid #eff1f3;
        height: 139px;
        width: 139px;
        position: relative;

        i {
          font-size: 111px;
          color: #c1001b;
          position: absolute;
          top: calc(50% - 55.5px);
          left: calc(50% - 55.5px);
        }
      }
    }
  }

  .pd-top10 {
    padding-top: 10px;
  }

  .tournament-select {
    width: calc(100% - 160px);
  }

  .tournament-search-button {
    width: 144px;
  }

  .filtering-column135 {
    min-width: 135px;
  }

  .filtering-column270 {
    min-width: 270px;
    max-width: 300px;
  }
}
</style>